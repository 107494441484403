import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';

import { ArticleCard } from '../article-card/ArticleCard';
import { ArticleTopFive } from '../article-top-five/ArticleTopFive';
import { SubscribeCard } from '../subscribe-card/SubscribeCard';

import './Arrows.scss';
import cn from './Articles.module.scss';

const INITIAL_ARTICLE_COUNT = 11;
const LOAD_MORE_COUNT = 6;
const MAX_COUNT = 50;

export interface Article {
  Description: string;
  Domain: string;
  Duration: string;
  Favicon: string;
  Images: string[];
  SiteName: string;
  Title: string;
  Url: string;
  UrlHash: number;
  DaysOnList: number;
}

export const Articles = () => {
  const [data, setData] = useState<Article[]>([]);
  const [count, setCount] = useState(INITIAL_ARTICLE_COUNT);
  const [hasMore, setHasMore] = useState(false);

  const articles = useMemo(() => data.slice(0, count), [count, data]);

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_AWS}/url_metadata.json`).then((data) => data.json());
        setData(res.slice(0, MAX_COUNT));
        setHasMore(res.length > INITIAL_ARTICLE_COUNT);
      } catch (error) {
        console.error('Error fetching CSV data:', error);
      }
    };

    fetchCSV();
  }, []);

  const onClick = useCallback(() => {
    const newCount = count + LOAD_MORE_COUNT;
    setCount(newCount);

    if (typeof gtag === 'function') {
      gtag('event', 'click', {
        send_to: 'G-TG1B5FR0SL',
        event_category: 'user interaction',
        event_label: 'Load More Articles',
      });
    }

    if (newCount >= data.length) {
      setHasMore(false);
    }
  }, [count, data.length]);

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    prevArrow: <div />,
    nextArrow: <div />,
  };

  return (
    <>
      {!data.length && <p className={cn.no_news}>No news is good news :)</p>}
      {!!data.length && (
        <div className={cn.articles}>
          <div className={cn.top_five}>
            <Slider {...settings}>
              {articles.slice(0, 5).map((article, index) => (
                <ArticleTopFive key={index} article={article} data={data} />
              ))}
            </Slider>
          </div>
          <h2 className={cn.header}>Top Positiv Stories</h2>
          <div className={cn.grid}>
            {articles.map((article, index) => (
              <ArticleCard key={index} article={article} place={index + 1} />
            ))}
            <SubscribeCard />
          </div>
          {hasMore && (
            <button className={cn.button} onClick={onClick}>
              LOAD MORE
            </button>
          )}
        </div>
      )}
    </>
  );
};
