import React, { useCallback, useState } from 'react';
import { faShare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Article } from '../articles/Articles';

import { ShareModal } from './modals/ShareModal';

import cn from './ArticleTopFive.module.scss';

export const ArticleTopFive = ({ article, data }: { article: Article; data: Article[] }) => {
  const { Title, Description, Images, SiteName, Url, DaysOnList } = article;
  const [usePlaceholder, setUsePlaceholder] = useState(false);
  const [open, setOpen] = useState(false);

  const onClickArticle = useCallback(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'click', {
        send_to: 'G-TG1B5FR0SL',
        event_category: 'user interaction',
        event_label: `${Title}: ${Url}`,
      });
    }
  }, [Title, Url]);

  const handleImageError = () => {
    setUsePlaceholder(true);
  };

  return (
    <>
      <div className={cn.card}>
        <div className={cn.info_container}>
          <div className={cn.header}>
            <h2>Today's Top Five</h2>
            <div className={cn.share} onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={faShare} />
            </div>
          </div>
          <div className={cn.grow} />
          <div className={cn.info}>
            <a tabIndex={-1} href={Url} aria-label={Title} target="_blank" rel="noreferrer" onClick={onClickArticle}>
              <h3 className={cn.title} title={Title}>
                {Title}
              </h3>
            </a>
            <a tabIndex={-1} href={Url} aria-label={Description} target="_blank" rel="noreferrer" onClick={onClickArticle} className={cn.description}>
              {Description}
            </a>
            <a
              tabIndex={-1}
              href={Url}
              aria-label={`${SiteName} - ${DaysOnList} ${DaysOnList === 1 ? 'Day' : 'Days'} on List`}
              target="_blank"
              rel="noreferrer"
              onClick={onClickArticle}
              className={cn.source}
            >
              <span className={cn.site_name}>{SiteName}</span> {DaysOnList && ` (${DaysOnList} ${DaysOnList === 1 ? 'Day' : 'Days'} on List)`}
            </a>
          </div>
          <div className={cn.grow} />
        </div>
        <a tabIndex={-1} href={Url} aria-label={Title} target="_blank" rel="noreferrer" onClick={onClickArticle} className={cn.img_container}>
          <div
            className={cn.img}
            style={{
              backgroundImage: `url("${usePlaceholder ? '/cognitiv-brain.svg' : Images[0]}")`,
              backgroundSize: usePlaceholder ? '50%' : 'cover',
              backgroundColor: usePlaceholder ? '#c6d3e8' : '',
              backgroundPosition: usePlaceholder ? 'center' : 'top',
            }}
            role="img"
            aria-label={Title}
          />
        </a>
        <img src={Images[0]} alt={Title} style={{ display: 'none' }} onError={handleImageError} />
        <div className={cn.header_mobile}>
          <h2>Today's Top Five</h2>
          <div className={cn.share} onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faShare} />
          </div>
        </div>
      </div>
      <ShareModal open={open} setOpen={setOpen} data={data} />
    </>
  );
};
