import React, { useEffect, useRef, useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight, faArrowTrendDown, faArrowTrendUp, faEquals, faMinus, faPlus, faShare, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { ShareModal } from './modals/ShareModal';
import { normalizeGraphData, normalizePulseData } from './operators/normalizers';
import { SentimentGraph } from './sentiment-graph/SentimentGraph';

import cn from './PositivPulse.module.scss';

export enum SENTIMENT {
  POSITIVE,
  NEUTRAL,
  NEGATIVE,
}

export interface PulseData {
  positive: number;
  negative: number;
  neutral: number;
  delta: number;
  delta_is_positive: boolean;
}

export interface GraphData {
  title: string;
  icon: IconDefinition;
  labels: string[];
  events: string[];
  data: number[];
}

export const PositivPulse: React.FC = () => {
  const [raw_data, setRawData] = useState<any>();
  const [sentiment, setSentiment] = useState<SENTIMENT>(SENTIMENT.POSITIVE);
  const [pulse_data, setPulseData] = useState<PulseData | null>(null);
  const [graph_data, setGraphData] = useState<GraphData | null>(null);
  const [loading, setLoading] = useState(true);
  const [is_open, setIsOpen] = useState(false);
  const [share_open, setShareOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        setRawData(await fetch(`${process.env.REACT_APP_AWS}/ratio_data.json`).then((data) => data.json()));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching CSV data:', error);
      }
    };

    fetchCSV();
  }, []);

  useEffect(() => {
    if (raw_data) {
      setPulseData(normalizePulseData(raw_data));
      setGraphData(normalizeGraphData(raw_data, sentiment));
    }
  }, [raw_data, sentiment]);

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.scrollHeight;
      setHeight(height);
    }
  }, [is_open, pulse_data, graph_data]);

  if (!pulse_data || isNaN(pulse_data.positive) || isNaN(pulse_data.neutral) || isNaN(pulse_data.negative) || isNaN(pulse_data.delta)) return null;

  return (
    <>
      <div className={cn.container} style={{ maxHeight: loading ? 0 : is_open ? height : 110 }} ref={containerRef}>
        <div className={cn.pulse}>
          <div className={classNames(cn.column, cn.top)}>
            <div className={cn.title}>
              <p>
                Positiv News Pulse: <span className={cn.delta}>{pulse_data.delta}%</span>
              </p>
              <div className={cn.ratio}>
                <div className={cn.trend}>
                  <FontAwesomeIcon
                    className={pulse_data.delta === 0 ? '' : pulse_data.delta_is_positive ? cn.increase : cn.decrease}
                    icon={pulse_data.delta === 0 ? faArrowRight : pulse_data.delta_is_positive ? faArrowTrendUp : faArrowTrendDown}
                  />
                </div>
              </div>
              <div className={cn.share} onClick={() => setShareOpen(true)}>
                <FontAwesomeIcon icon={faShare} />
              </div>
            </div>
            <p className={cn.text}>
              {`Positive sentiment news is ${
                pulse_data.delta === 0 ? 'unchanged' : pulse_data.delta_is_positive ? `up ${pulse_data.delta}%` : `down ${pulse_data.delta}%`
              } from
                yesterday`}
            </p>
          </div>

          <div className={classNames(cn.column, cn.content)} style={{ opacity: is_open ? 1 : 0 }}>
            <div className={cn.overall_sentiment}>
              <p>Overall Sentiment:</p>
              <div className={cn.ratios}>
                <div
                  className={classNames(cn.ratio, { [cn.selected]: sentiment === SENTIMENT.POSITIVE })}
                  onClick={() => setSentiment(SENTIMENT.POSITIVE)}
                >
                  <div className={cn.icon}>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <p>{pulse_data.positive}%</p>
                </div>
                <div
                  className={classNames(cn.ratio, { [cn.selected]: sentiment === SENTIMENT.NEUTRAL })}
                  onClick={() => setSentiment(SENTIMENT.NEUTRAL)}
                >
                  <div className={cn.icon}>
                    <FontAwesomeIcon icon={faEquals} />
                  </div>
                  <p>{pulse_data.neutral}%</p>
                </div>
                <div
                  className={classNames(cn.ratio, { [cn.selected]: sentiment === SENTIMENT.NEGATIVE })}
                  onClick={() => setSentiment(SENTIMENT.NEGATIVE)}
                >
                  <div className={cn.icon}>
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                  <p>{pulse_data.negative}%</p>
                </div>
              </div>
            </div>
            <p className={cn.text}>
              <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 16, marginRight: 8 }} />
              The Positiv News Pulse shows the daily ratio of positive and negative news identified by our AI, with all other content classified as
              neutral. This tracker showcases our AI's ability to understand content and measure the balance of sentiment in online news, highlighting
              the need for more uplifting stories in today's digital landscape.
            </p>
          </div>
          {!!graph_data && <SentimentGraph {...graph_data} />}
        </div>
      </div>
      <div className={classNames(cn.knob, { [cn.open]: is_open, [cn.loading]: loading })} onClick={() => setIsOpen(!is_open)} />
      <ShareModal open={share_open} setOpen={setShareOpen} data={pulse_data} />
    </>
  );
};
