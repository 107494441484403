import React, { useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../modal/Modal';

import cn from './Banner.module.scss';

export const Banner = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={cn.banner}>
        <div className={cn.title}>
          <h1>Positive News</h1>
          <div className={cn.info} onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
        </div>
        <h2 className={cn.subtitle}>AI Bringing You Brighter News</h2>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <p className={cn.description}>
          At More Positiv, we curate uplifting news, highlighting breakthroughs, discoveries, and inspiring stories. Powered by advanced AI, we
          analyze content sentiment to deliver the top positive stories each day. With the Positiv News Pulse, stay updated on the overall sentiment
          of online news daily. Join us in celebrating the brighter side of news!
        </p>
      </Modal>
    </>
  );
};
