import React from 'react';

import { Articles } from './components/articles/Articles';
import { Banner } from './components/banner/Banner';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { PositivPulse } from './components/positiv-pulse/PositivPulse';

import cn from './ApplicationTemplate.module.scss';

export default () => {
  return (
    <>
      <div className={cn.page}>
        <Header />
        <PositivPulse />
        <Banner />
        <Articles />
        <Footer />
      </div>
    </>
  );
};
