import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from './Modal.module.scss';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  width?: number;
  background?: string;
  icon?: IconDefinition;
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
}

export const Modal: FC<ModalProps> = ({ open, onClose, width, background = '#fff', icon, header, footer, children }) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <div className={cn.overlay} onClick={onClose}>
      <div className={cn.modal} onClick={(e) => e.stopPropagation()} style={{ width, maxWidth: width, background }}>
        <div className={cn.header}>
          {icon && <FontAwesomeIcon icon={icon} />}
          {header && header}
          <div />
          <FontAwesomeIcon className={cn.close} icon={faX} onClick={onClose} />
        </div>
        <div className={cn.content}>{children}</div>
        {footer && <div className={cn.footer}>{footer}</div>}
      </div>
    </div>,
    document.body,
  );
};
